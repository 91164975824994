var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('All',{ref:"coursesRef",attrs:{"model":"Course","immediate":true,"query":_vm.coursesFilter,"data":_vm.coursesResponse},on:{"loading:change":function (event) { return (_vm.loading = event); },"update:data":function($event){_vm.coursesResponse=$event}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var courses = ref.data;
return [_c('SectionWrapper',[_c('div',{staticClass:"w-full px-1 sm:px-2 xl:px-5"},[_c('Header',{attrs:{"title":_vm.title}}),_c('div',{staticClass:"py-2 w-full flex flex-row items-between justify-center"},[_c('perPageSelector',{on:{"changeOption":_vm.changePerPage}}),_c('div',{staticClass:"w-auto sm:w-1/3 flex flex-row flex-grow justify-center sm:justify-center sm:px-6"},[_c('new-show',{attrs:{"entity":_vm.entity,"selectedItem":_vm.elSelected,"action":'new'},on:{"refreshDataTable":_vm.refreshDataTable}}),(_vm.elSelected)?_c('new-show',{attrs:{"entity":_vm.entity,"selectedItem":_vm.elSelected,"action":'show'},on:{"refreshDataTable":_vm.refreshDataTable}}):_vm._e(),(_vm.elSelected)?_c('destroy-mod',{attrs:{"entity":_vm.entity},on:{"refreshDataTable":_vm.refreshDataTable}}):_vm._e()],1),_c('searcher',{on:{"changeText":_vm.changeText}})],1),_c('Table',{attrs:{"data":courses.data,"fields":_vm.fields,"fieldBy":_vm.coursesFilter.field,"direction":_vm.coursesFilter.direction,"loading":_vm.loading},on:{"changeField":_vm.changeField,"changeDirection":_vm.changeDirection},scopedSlots:_vm._u([{key:"customers_size",fn:function(ref){
var fullRow = ref.fullRow;
return [(fullRow.customers_target && fullRow.customers_size)?_c('p',{staticClass:"py-4 px-2 truncate"},[_vm._v(_vm._s(fullRow.customers_size))]):(fullRow.customers_target && !fullRow.customers_size)?_c('p',{staticClass:"py-4 px-2 truncate"},[_vm._v("0")]):_c('p')]}},{key:"customers_target_percent_reached",fn:function(ref){
var fullRow = ref.fullRow;
return [_c('CellTargetReached',{attrs:{"fullRow":fullRow}})]}},{key:"opportunities_plus_size",fn:function(ref){
var fullRow = ref.fullRow;
return [(fullRow.opportunities_plus_target && fullRow.opportunities_plus_size)?_c('p',{staticClass:"py-4 px-2 truncate"},[_vm._v(_vm._s(fullRow.opportunities_plus_size))]):(fullRow.opportunities_plus_target && !fullRow.opportunities_plus_size)?_c('p',{staticClass:"py-4 px-2 truncate"},[_vm._v("0")]):_c('p')]}},{key:"opportunities_plus_target_percent_reached",fn:function(ref){
var fullRow = ref.fullRow;
return [_c('div',{staticClass:"flex h-full justify-center items-center"},[(fullRow.area_has_opportunities_plus && fullRow.opportunities_plus_target_percent_reached)?_c('div',{staticClass:"p-1 rounded-full w-14 h-14 border-4 flex justify-center items-center",class:{
                                'border-opacity-100': fullRow.com_remaining_days > 30,
                                'border-bad': fullRow.com_light === 'bad',
                                'border-regular': fullRow.com_light === 'regular',
                                'border-good': fullRow.com_light === 'good',
                            }},[_vm._v(" "+_vm._s(_vm._f("numberFormat")(fullRow.opportunities_plus_target_percent_reached,0))+"% ")]):(fullRow.area_has_opportunities_plus && fullRow.opportunities_plus_size)?_c('div',{staticClass:"p-1 rounded-full w-14 h-14 border-4 bg-bad flex justify-center items-center"},[_vm._v(_vm._s(_vm._f("numberFormat")(0,0))+"%")]):_vm._e()])]}},{key:"current_opportunities_plus_weight",fn:function(ref){
                            var fullRow = ref.fullRow;
return [(fullRow.opportunities_plus_target)?_c('p',{staticClass:"py-4 px-2 truncate"},[_vm._v(_vm._s(fullRow.current_opportunities_plus_weight))]):_c('p',{staticClass:"py-4 px-2 truncate"})]}},{key:"com_progress_percent",fn:function(ref){
                            var fullRow = ref.fullRow;
return [(fullRow.com_progress_percent || fullRow.com_progress_percent === 0)?_c('CellComProgressInfo',{attrs:{"fullRow":fullRow}}):_vm._e()]}}],null,true)}),(courses.meta)?_c('paginate',{attrs:{"meta":courses.meta},on:{"changePage":_vm.changePage}}):_vm._e(),(courses.meta)?_c('PaginateTextInfo',{staticClass:"flex-auto text-center sm:hidden",attrs:{"meta":courses.meta}}):_vm._e(),_c('paginate-mobile',{staticClass:"sm:hidden",attrs:{"links":courses.links},on:{"changePage":_vm.changePage}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }