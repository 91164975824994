<template>
    <div>
        <button type="button" @click="show = !show" class="p-2 mr-2 w-12 bg-bo-form shadow-md rounded-md focus:outline-none flex justify-center items-center">
            <i v-if="action === 'show'" class="mdi mdi-eye text-2xl font-semibold leading-none"></i>
            <i v-if="action === 'new'" class="mdi mdi-plus text-2xl font-semibold leading-none"></i>
        </button>
        <ModalBase :width="'w-95por'" :show="show" @close="closeModal">
            <form @submit.prevent="store">
                <Request v-if="action === 'show'" model="Course" action="byId" :form="courseFilter" @success="onSuccessGetCourse" @error="onErrorGetCourse" ref="getCourseRef" />

                <div class="flex flex-col overflow-y-scroll xl:overflow-y-hidden h-full">
                    <ModalHeader :type="action" :editable="editable && action === 'show'" :entity="entity" @close="closeModal" />
                    <div class="flex flex-col xl:flex-row overflow-y-scroll xl:overflow-y-hidden">
                        <div class="flex flex-col w-full xl:w-1/3 justify-start items-center p-4 xl:overflow-y-auto h-auto xl:h-100 xl:border-r xl:border-gray-900">
                            <div class="mt-1 w-full">
                                <span>Nombre</span>
                                <input class="form-input mt-1 w-full text-center text-bo-input" :class="{ 'bg-gray-200 noselect': !editable && action === 'show' }" placeholder="Nombre" v-model="form.name" ref="nameRef" required />
                            </div>
                            <div class="mt-1 sm:mt-4 w-full">
                                <span>Analytics url</span>
                                <input class="form-input mt-1 w-full text-center text-bo-input" :class="{ 'bg-gray-200 noselect': !editable && action === 'show' }" placeholder="Analytics url" :disabled="!editable && action === 'show'" v-model="form.ga_path" required />
                            </div>
                            <div class="mt-1 sm:mt-4 w-full">
                                <span>Área</span>

                                <All v-if="action === 'show'" model="Area" :immediate="true" v-slot="{ data: areas }">
                                    <select class="form-select w-full text-center text-bo-input" v-model="form.area_id" :class="{ 'bg-gray-200 noselect': !editable && action === 'show' }" :disabled="!editable && action === 'show'" required>
                                        <option @click="areaSelected(item)" v-for="item in areas.data" :key="item.id" :value="item.id" :selected="form.area_id === item.id">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </All>

                                <All v-if="action === 'new'" model="Area" :immediate="false" v-slot="{ data: areas }" :query="areasFilter" :data.sync="areasResponse" ref="areasRef">
                                    <select class="form-select w-full text-center text-bo-input" v-model="form.area_id" ref="areasSelect" required>
                                        <option @click="areaSelected(item)" v-for="item in areas.data" :key="item.id" :value="item.id">
                                            {{ item.name }}
                                        </option>
                                    </select>
                                </All>
                            </div>
                            <div v-if="action === 'show'" class="mt-1 sm:mt-4 w-full">
                                <span>Ediciones</span>
                                <div class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <EditionSelector :prefix="'editions'" :courseId="form.id" :resetSelectionFlagForSubTable="resetSelectionFlagForSubTable" @selection="selection" @refreshDataTable="refreshDataTable" />
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col w-full xl:w-2/3 justify-start items-center p-4 xl:overflow-y-auto h-auto xl:h-100">
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">informados</span>
                                    <input v-model="form.info_requests_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left p-2 truncate" :class="form.info_requests_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('infoRequests')" ref="infoRequestsNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('infoRequests', 'info_requests_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="infoRequestsSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'infoRequests'" :target="'info_requests_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div class="flex flex-col xl:flex-row w-full">
                                <label class="mt-4 w-full">
                                    <span class="font-normal capitalize">inscritos</span>
                                    <input v-model="form.inscriptions_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left p-2 truncate" :class="form.inscriptions_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('inscriptions')" ref="inscriptionsNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('inscriptions', 'inscriptions_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </label>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="inscriptionsSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'inscriptions'" :target="'inscriptions_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div class="flex flex-row justify-center w-full border-t border-gray-900 mt-8 p-2"><p>Listas Hubspot por defecto para las ediciones del curso</p></div>
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="w-full">
                                    <span class="font-normal capitalize">leads</span>
                                    <input v-model="form.leads_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none w-full">
                                        <div class="w-full text-left p-2 truncate" :class="form.leads_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('leads')" ref="leadsNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('leads', 'leads_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="leadsSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'leads'" :target="'leads_hp_list'"></HpListSelector>
                                </div>
                            </transition>

                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">SQL En Proceso</span>
                                    <input v-model="form.sql_in_process_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left p-2 truncate" :class="form.sql_in_process_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('sqlProcess')" ref="sqlProcessNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('sqlProcess', 'sql_in_process_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="sqlProcessSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'sqlProcess'" :target="'sql_in_process_hp_list'"></HpListSelector>
                                </div>
                            </transition>

                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">SQL</span>
                                    <input v-model="form.sql_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left p-2 truncate" :class="form.sql_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('sql')" ref="sqlNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('sql', 'sql_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="sqlSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'sql'" :target="'sql_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">oportunidades</span>
                                    <input v-model="form.opportunities_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left p-2 truncate" :class="form.opportunities_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('opportunities')" ref="opportunitiesNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('opportunities', 'opportunities_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="opportunitiesSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'opportunities'" :target="'opportunities_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div v-show="form.area_has_opportunities_plus" class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">oportunidades plus</span>
                                    <input v-model="form.opportunities_plus_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left p-2 truncate" :class="form.opportunities_plus_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('opportunitiesPlus')" ref="opportunitiesPlusNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('opportunitiesPlus', 'opportunities_plus_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="opportunitiesPlusSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'opportunitiesPlus'" :target="'opportunities_plus_hp_list'"></HpListSelector>
                                </div>
                            </transition>
                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">clientes</span>
                                    <input v-model="form.customers_hp_list" class="text-bo-input hidden" />
                                    <div class="mt-1 flex flex-row form-input cursor-pointer p-0 h-10 border-none">
                                        <div class="w-full text-left p-2 truncate" :class="form.customers_hp_list ? 'text-bo-input' : 'text-gray-500'" @click="selectorToggle('customers')" ref="customersNameRef">
                                            {{ selectOptionMsg }}
                                        </div>
                                        <button type="button" @click="clear('customers', 'customers_hp_list')" class="focus:outline-none bg-gray-500 hover:bg-gray-300 border text-bo-input rounded-r-md w-10">
                                            <i class="mdi mdi-close text-xl"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <transition name="slide-fade">
                                <div v-if="customersSelectorExpanded" class="flex flex-row w-full p-2 mt-1 bg-gray-300 rounded rounded-md shadow-md">
                                    <HpListSelector @selection="selection" :prefix="'customers'" :target="'customers_hp_list'"></HpListSelector>
                                </div>
                            </transition>

                            <div class="flex flex-col xl:flex-row w-full">
                                <div class="mt-4 w-full">
                                    <span class="font-normal capitalize">Objetivos</span>
                                    <div class="w-full flex justify-between">
                                        <div class="md:w-3/12 w-full flex flex-col">
                                            <span class="text-sm text-btn">Selecciona el año</span>
                                            <select v-if="action === 'new'" v-model="yearSelected" class="md:w-40 md:mr-0 mr-4 w-32 h-8 text-bo-input rounded-md">
                                                <option disabled value="">Selecciona un año</option>
                                                <option v-for="(year, index) in years" :key="'year' + index">{{ year }}</option>
                                            </select>

                                            <select v-if="action === 'show'" v-model="editSelectedYear" class="md:w-40 md:mr-0 mr-4 w-32 h-8 text-bo-input rounded-md" :disabled="!editable && action === 'show'">
                                                <option disabled value="">Selecciona un año</option>
                                                <option v-for="(year, index) in years" :key="'year' + index">{{ year }}</option>
                                            </select>
                                        </div>
                                        <div class="md:w-9/12 w-full flex flex-wrap">
                                            <template v-if="action === 'show'" class="flex w-full">
                                                <div v-for="(month, index) in months" :key="index" class="flex items-center mr-4 mb-2">
                                                    <div class="w-6 mr-2 uppercase text-sm">{{ month.name.slice(0, 3) }}</div>
                                                    <input v-model="objectives[editSelectedYear][month.month].objective" :placeholder="'ej: 234'" class="text-bo-input w-20 px-2 ml-4 rounded-md" type="number" :disabled="!editable && action === 'show'" />
                                                </div>
                                            </template>
                                            <template v-if="action === 'new'" class="flex w-full">
                                                <div v-for="(month, index) in months" :key="index" class="flex items-center mr-4 mb-2">
                                                    <div class="w-6 mr-2 uppercase text-sm">{{ month.name.slice(0, 3) }}</div>
                                                    <input v-model="objectives[yearSelected][month.month].objective" :placeholder="'ej: 234'" class="text-bo-input w-20 px-2 ml-4 rounded-md" type="number" :disabled="!editable && action === 'show'" />
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalFooter @close="closeModal">
                    <Request v-if="action === 'show'" model="Course" action="update" :form="form" @success="onSuccess" @error="onError" ref="updateCourseRef" />
                    <Request v-if="action === 'new'" model="Course" action="store" :form="form" @success="onSuccess" @error="onError" ref="storeCourseRef" />
                    <ActionBtn v-show="!editable && action === 'show'" :action="'edit'" @edit="edit" />
                    <ActionBtn v-show="editable" :action="'store'" @store="store" />
                </ModalFooter>
            </form>
        </ModalBase>
    </div>
</template>

<script>
    import { actions } from '@/store';
    import { Request } from '@/api/components';
    import ModalBase from '@/pages/inside/shared/modals/ModalBase';
    import ModalHeader from '@/pages/inside/shared/modals/ModalHeader';
    import All from '@/api/components/All';
    import ActionBtn from '@/pages/inside/shared/modals/ActionBtn';
    import ModalFooter from '@/pages/inside/shared/modals/ModalFooter';
    import EditionSelector from '@/pages/inside/shared/modals/EditionSelector';
    import HpListSelector from '@/pages/inside/shared/modals/HpListSelector';

    export default {
        name: 'NewShow',
        components: {
            Request,
            ModalBase,
            ModalHeader,
            ModalFooter,
            All,
            ActionBtn,
            EditionSelector,
            HpListSelector,
        },
        props: ['entity', 'selectedItem', 'action'],
        data() {
            return {
                years: ['2022', '2021', '2020'],
                yearSelected: '2022',
                editSelectedYear: '2022',
                objectives: {},
                months: [
                    {
                        month: '01',
                        name: 'enero',
                    },
                    {
                        month: '02',
                        name: 'febrero',
                    },
                    {
                        month: '03',
                        name: 'marzo',
                    },
                    {
                        month: '04',
                        name: 'abril',
                    },
                    {
                        month: '05',
                        name: 'mayo',
                    },
                    {
                        month: '06',
                        name: 'junio',
                    },
                    {
                        month: '07',
                        name: 'julio',
                    },
                    {
                        month: '08',
                        name: 'agosto',
                    },
                    {
                        month: '09',
                        name: 'septiembre',
                    },
                    {
                        month: '10',
                        name: 'octubre',
                    },
                    {
                        month: '11',
                        name: 'noviembre',
                    },
                    {
                        month: '12',
                        name: 'diciembre',
                    },
                ],
                show: false,
                editable: false,
                areasFilter: {},
                areasResponse: false,
                form: {
                    id: null,
                    name: null,
                    ga_path: null,
                    area_id: null,
                    info_requests_hp_list: null,
                    inscriptions_hp_list: null,
                    leads_hp_list: null,
                    sql_hp_list: null,
                    sql_in_process_hp_list: null,
                    opportunities_hp_list: null,
                    opportunities_plus_hp_list: null,
                    customers_hp_list: null,
                    area_has_opportunities_plus: false,
                    editions: {},
                    objective_request: {},
                },
                infoRequestsSelectorExpanded: false,
                inscriptionsSelectorExpanded: false,
                leadsSelectorExpanded: false,
                sqlSelectorExpanded: false,
                sqlProcessSelectorExpanded: false,
                opportunitiesSelectorExpanded: false,
                opportunitiesPlusSelectorExpanded: false,
                customersSelectorExpanded: false,
                courseFilter: {
                    id: null,
                },
                resetSelectionFlagForSubTable: false,
            };
        },
        watch: {
            /*editSelectedYear(n) {
            this.objectives = [];

            console.log(this.form.objective_request[n])

            this.months.forEach(month => {

                this.form.objective_request[n].forEach( objective => {

                    if (month.month === objective.date.split('-')[2]) {
                        this.objectives.push({
                            objective: objective.objective,
                            date: objective.date,
                            month: month.name

                        });
                    }
                })

                /!*
                if (this.form.objective_request[n].date){
                    if (mes.month === this.form.objective_request[n].date.split('-')[2]) {
                        this.objectives.push({
                            objective: this.form.objective_request[n].objective,
                            date: this.form.objective_request[n].date,
                            month: mes.name

                        });
                    }
                }else{
                    this.objectives.push({
                        objective: null,
                        date: this.form.objective_request[n].date,
                        month: mes.name

                    });
                }
                *!/
            })

            console.log(this.objectives)
        },*/
            show(n) {
                if (this.action === 'show') {
                    if (n) {
                        this.courseFilter.id = this.elSelected.id;
                        this.$refs.getCourseRef.request();
                        this.$refs.nameRef.disabled = true;
                    } else {
                        this.resetSelectionFlagForSubTable = !this.resetSelectionFlagForSubTable;
                        this.editable = false;
                    }
                }
                if (this.action === 'new') {
                    this.editable = true;
                    if (n) {
                        this.$refs.areasRef.request();
                    } else {
                        this.resetForm();
                    }
                }
            },
            /*yearSelected() {
                this.form.objective_request = this.months.map(month => ({
                    date: this.yearSelected + '-' + month.month + '-01',
                    month: month.name.slice(0, 3),
                }));
            },*/
        },

        computed: {
            elSelected() {
                return actions.getElSelected();
            },
        },
        methods: {
            closeModal() {
                this.show = false;
                this.setUpObjectives();
            },
            edit() {
                this.editable = !this.editable;
                if (this.editable) {
                    this.$refs.nameRef.disabled = false;
                    this.$refs.nameRef.focus();
                }
            },
            store() {
                this.setUpObjectivesStore();
                if (this.action === 'show') {
                    this.$refs.updateCourseRef.request();
                }
                if (this.action === 'new') {
                    this.$refs.storeCourseRef.request();
                }
                this.setUpObjectives();
            },
            onError(errors) {
                this.toastErrors(errors);
            },
            onSuccess(response) {
                this.$emit('refreshDataTable');
                this.response = JSON.parse(JSON.stringify(response));
                this.$notify({ group: 'generic', title: 'Info', text: this.response.data.message, type: 'info' }, this.millis);
                this.show = false;
            },
            onSuccessGetCourse(course) {
                this.setForm(course);
                this.setListNames(course);
                actions.setElSelected(course);
            },
            onErrorGetCourse(errors) {
                this.toastErrors(errors);
            },
            setForm(course) {
                if (this.action === 'show') {
                    this.form.id = course.id;
                    this.form.name = course.name;
                    this.form.ga_path = course.ga_path;
                    this.form.area_id = course.area_id;
                    this.form.info_requests_hp_list = course.default_info_requests_hp_list;
                    this.form.inscriptions_hp_list = course.default_inscriptions_hp_list;
                    this.form.leads_hp_list = course.default_leads_hp_list;
                    this.form.sql_hp_list = course.default_sql_hp_list;
                    this.form.sql_in_process_hp_list = course.default_sql_in_process_hp_list;
                    this.form.customers_hp_list = course.default_customers_hp_list;
                    this.form.opportunities_hp_list = course.default_opportunities_hp_list;
                    this.form.area_has_opportunities_plus = course.area_has_opportunities_plus;
                    this.form.opportunities_plus_hp_list = course.area_has_opportunities_plus ? course.default_opportunities_plus_hp_list : null;
                    this.form.objective_request = course.objective_request;
                    this.form.editions = course.editions;

                    //console.log(this.objectives);
                    //console.log(this.editSelectedYear);
                    //console.log(this.objectives[this.editSelectedYear]);

                    //asignamos a cada MONTH de YEAR de objectives con los valores
                    //que vengan del curso

                    this.years.forEach(year => {
                        Object.entries(this.objectives[year]).forEach(item => {
                            let month = parseInt(item[0]);
                            let data = item[1];

                            if (this.form.objective_request[year]) {
                                if (this.form.objective_request[year][month]) {
                                    data.objective = this.form.objective_request[year][month].objective;
                                }
                            }
                        });
                    });
                }
            },
            setUpObjectivesStore() {
                this.form.objective_request = this.objectives;
            },
            setUpObjectives() {
                this.objectives = {};
                this.editSelectedYear = this.years[0];

                this.years.forEach(year => {
                    this.objectives[year] = {};
                    this.months.forEach(month => {
                        let indexHelp = month.month.toString();

                        this.objectives[year][indexHelp] = {
                            date: year + '-' + month.month + '-01',
                            objective: 0,
                        };
                    });
                });
            },
            setListNames(course) {
                if (this.form.info_requests_hp_list) {
                    this.$refs.infoRequestsNameRef.textContent = course.default_info_requests_hp_list_name;
                }
                if (this.form.inscriptions_hp_list) {
                    this.$refs.inscriptionsNameRef.textContent = course.default_inscriptions_hp_list_name;
                }
                if (this.form.leads_hp_list) {
                    this.$refs.leadsNameRef.textContent = course.default_leads_hp_list_name;
                }
                if (this.form.sql_in_process_hp_list) {
                    this.$refs.sqlProcessNameRef.textContent = course.default_sql_in_process_hp_list_name;
                }
                if (this.form.sql_hp_list) {
                    this.$refs.sqlNameRef.textContent = course.default_sql_hp_list_name;
                }
                if (this.form.opportunities_hp_list) {
                    this.$refs.opportunitiesNameRef.textContent = course.default_opportunities_hp_list_name;
                }
                if (this.form.opportunities_plus_hp_list && course.area_has_opportunities_plus) {
                    this.$refs.opportunitiesPlusNameRef.textContent = course.default_opportunities_plus_hp_list_name;
                }
                if (this.form.customers_hp_list) {
                    this.$refs.customersNameRef.textContent = course.default_customers_hp_list_name;
                }
            },
            selection(prefix, target, el) {
                this.$refs[prefix + 'NameRef'].textContent = el.name;
                this.form[target] = el.id;
                this.selectorToggle(prefix + 'Selector');
            },
            refreshDataTable() {
                actions.setSubElSelected(false);
                this.$emit('refreshDataTable', true);
            },
            selectorToggle(prefix) {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                let selectorStatusKeeper = this[prefix + 'SelectorExpanded'];
                this.collapseAllSelectors();
                this[prefix + 'SelectorExpanded'] = !selectorStatusKeeper;
            },
            collapseAllSelectors(prefix) {
                let selectorStatusKeeper = this[prefix + 'SelectorExpanded'];
                this.infoRequestsSelectorExpanded = false;
                this.inscriptionsSelectorExpanded = false;
                this.leadsSelectorExpanded = false;
                this.sqlSelectorExpanded = false;
                this.sqlProcessSelectorExpanded = false;
                this.opportunitiesSelectorExpanded = false;
                this.opportunitiesPlusSelectorExpanded = false;
                this.customersSelectorExpanded = false;
                this[prefix + 'SelectorExpanded'] = selectorStatusKeeper;
            },
            areaSelected(area) {
                this.form.area_has_opportunities_plus = !!area.has_opportunities_plus;
            },
            clear(prefix, target) {
                if (!this.editable) {
                    this.showModeNotifier();
                    return;
                }
                this.$refs[prefix + 'NameRef'].textContent = this.selectOptionMsg;
                this.form[target] = null;
                if (this[prefix + 'SelectorExpanded']) {
                    this.selectorToggle(prefix + 'Selector');
                }
            },
            resetForm() {
                this.form.name = null;
                this.form.ga_path = null;
                this.form.area_id = null;
                this.form.info_requests_hp_list = null;
                this.form.inscriptions_hp_list = null;

                this.form.leads_hp_list = null;
                this.form.sql_hp_list = null;
                this.form.opportunities_hp_list = null;
                this.form.opportunities_plus_hp_list = null;
                this.form.customers_hp_list = null;
                this.form.area_has_opportunities_plus = null;

                this.areasFilter = {};

                this.areasResponse.infoRequestsSelectorExpanded = false;
                this.areasResponse.inscriptionsSelectorExpanded = false;
                this.areasResponse.leadsSelectorExpanded = false;
                this.areasResponse.sqlSelectorExpanded = false;
                this.areasResponse.sqlProcessSelectorExpanded = false;
                this.areasResponse.opportunitiesSelectorExpanded = false;
                this.areasResponse.opportunitiesPlusSelectorExpanded = false;
                this.areasResponse.customersSelectorExpanded = false;

                this.$refs.infoRequestsNameRef.textContent = this.selectOptionMsg;
                this.$refs.inscriptionsNameRef.textContent = this.selectOptionMsg;
                this.$refs.leadsNameRef.textContent = this.selectOptionMsg;
                this.$refs.sqlNameRef.textContent = this.selectOptionMsg;
                this.$refs.sqlProcessNameRef.textContent = this.selectOptionMsg;
                this.$refs.opportunitiesNameRef.textContent = this.selectOptionMsg;
                this.$refs.opportunitiesPlusNameRef.textContent = this.selectOptionMsg;
                this.$refs.customersNameRef.textContent = this.selectOptionMsg;
            },
        },
        beforeMount() {
            this.setUpObjectives();
        },
    };
</script>
