<template>
    <All model="Edition" v-slot="{data:editions}" :query="editionsFilter" @loading:change="(event) => loading = event" ref="editionsRef">
        <div class="flex flex-col w-full justfy-center items-end text-bo-input">
            <div class="flex flex-col sm:flex-row w-full">
                <div class="w-full sm:w-1/2 flex justify-start sm:justify-start">
                  <new-mod-ed :entity="'Edición'" :type="'subModal'" @refreshDataTable="refreshDataTable"/>
                  <show-mod-ed v-if="el" :entity="'Edición'" :type="'subModal'" @refreshDataTable="refreshDataTable"/>
                  <destroy-mod-ed v-if="el" :entity="'Edición'" :type="'subModal'" @refreshDataTable="refreshDataTable"/>
                </div>
                <div class="w-full sm:w-1/2">
                    <searcher @changeText="changeText" :focusDisabled="true"/>
                </div>
            </div>
            <EditionSubTable
                :data="editions.data" :fields="fields" :loading="loading"
                :page="editionsFilter.page" :resetRowSelected="resetRowSelected"
                @selectRow="selectRow" class="mt-2">

                <template v-slot:com_progress_percent="{ fullRow }">
                    <CellComProgressInfo v-if="fullRow.com_progress_percent || fullRow.com_progress_percent === 0" :fullRow="fullRow"/>
                </template>
            </EditionSubTable>
            <div class="w-full flex flex-row flex-wrap justify-end items-center pt-1">
                <PaginateTextInfo v-if="editions.meta" :meta="editions.meta" class="flex-auto text-center"/>
<!--                <PaginateLinks v-if="editions.meta" :meta="editions.meta" @changePage="changePage" class="hidden md:flex"/>-->
                <PaginateMobile v-if="editions.links" :links="editions.links" @changePage="changePage"/>
            </div>
        </div>
    </All>
</template>

<script>
import EditionSubTable from "@/pages/inside/shared/datatables/EditionSubTable";
import Searcher from "@/pages/inside/shared/datatables/Searcher";
import PaginateMobile from "@/pages/inside/shared/datatables/PaginateMobile";
import PaginateTextInfo from "@/pages/inside/shared/datatables/PaginateTextInfo";
import All from "@/api/components/All";
import ShowModEd from "@/pages/inside/sections/editions/modals/Show";
import NewModEd from "@/pages/inside/sections/editions/modals/New";
import DestroyModEd from "@/pages/inside/sections/editions/modals/Destroy";
import CellComProgressInfo from "@/pages/inside/shared/datatables/cells/CellComProgressInfo";

export default {
    name: "EditionSelector",
    props: ['courseId', 'resetSelectionFlagForSubTable'],
    components: {
        All, EditionSubTable,  Searcher, PaginateMobile, PaginateTextInfo, ShowModEd, NewModEd, DestroyModEd, CellComProgressInfo
    },
    data() {
        return {
            loading: false,
            editionsFilter: {
                course: this.courseId,
                com: null,
                page: null,
                perPage: 10,
                field: 'com_end_date',
                direction: 'desc',
                text: null
            },
            el: null,
            resetRowSelected: false,
            fields,
        }
    },
    watch: {
        courseId(n) {
            this.editionsFilter.course = n;
            this.$refs.editionsRef.request();
        },
        async resetSelectionFlagForSubTable() {
            this.el = null;
            this.resetRowSelected = !this.resetRowSelected;
        }
    },
    methods: {
        changePage(page){
            this.editionsFilter.page = page;
            this.refreshDataTable()
        },
        changeText(text){
            this.editionsFilter.page = null;
            this.editionsFilter.text = text;
            this.refreshDataTable()
        },
        selectRow(el) {
            this.el = el;
        },
        refreshDataTable() {
            this.$refs.editionsRef.request(); // Para hacer la request
            this.el = null;
            this.resetRowSelected = !this.resetRowSelected;
            this.$emit('refreshDataTable');
        }
    }
};

const fields = [
    {
        label: 'Edición',
        key: 'name'
    },
    {
        label: 'Progreso Comercialización',
        key: 'com_progress_percent'
    },
];
</script>

<style scoped>

</style>