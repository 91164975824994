import { render, staticRenderFns } from "./Courses.vue?vue&type=template&id=8d9f0a00&scoped=true&"
import script from "./Courses.vue?vue&type=script&lang=js&"
export * from "./Courses.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d9f0a00",
  null
  
)

export default component.exports